import { Link } from "react-router-dom";

import FormGroup from "components/FormDefault/Group";
import { AzureIntegration } from "types/generated";
import FormViewer from "components/FormDefault/Viewer";
import InfoBox from "components/InfoBox";
import AttachedProjects from "components/AttachedProjects";
import TagsList from "components/TagsList";

import styles from "./styles.module.css";

type AzureIntegrationViewFormProps = {
  integration: AzureIntegration;
  adminConsentFailed: boolean;
};

const AzureIntegrationViewForm = ({
  integration,
  adminConsentFailed,
}: AzureIntegrationViewFormProps) => {
  const adminConsentButtonText = integration.adminConsentProvided
    ? "Re-consent"
    : "Provide Consent";

  return (
    <div>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Name:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.name}</FormViewer>
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Tenant ID:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.tenantId}</FormViewer>
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Application ID:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.applicationId}</FormViewer>
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Display Name:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.displayName}</FormViewer>
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Default Subscription ID:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.defaultSubscriptionId ?? "No default subscription"}</FormViewer>
      </FormGroup>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Space:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>
          <Link to={`/spaces/${integration.spaceDetails.id}`} className={styles.itemLink}>
            {integration.spaceDetails.name}
          </Link>
        </FormViewer>
      </FormGroup>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Labels:"
        labelStyle={{
          muted: true,
        }}
        divided
      >
        <FormViewer>
          <TagsList tags={integration.labels} />
        </FormViewer>
      </FormGroup>

      <h4>Admin Consent</h4>

      {!adminConsentFailed && integration.adminConsentProvided && (
        <InfoBox>
          Your integration is ready to be used. You can use the <em>{adminConsentButtonText}</em>{" "}
          button to grant admin consent again if you uninstall the application from your directory
          by accident.
        </InfoBox>
      )}

      {!adminConsentFailed && !integration.adminConsentProvided && (
        <InfoBox>
          <p>
            Please use the <em>{adminConsentButtonText}</em> button to grant admin consent to the
            Spacelift Active Directory application created for your integration. This installs the
            application in your directory tenant, allowing you to grant RBAC permissions to it.
          </p>
          <p>
            Note that it can take several minutes for your application to become available in Azure.
            If the admin consent process initially fails, please try again in a few minutes.
          </p>
        </InfoBox>
      )}

      {adminConsentFailed && (
        <InfoBox>
          <p>
            The Azure admin consent process has failed. This can be caused by a delay before the
            Azure AD application for your integration has been fully replicated within Azure.
          </p>
          <p>
            If you have created this integration within the last 10 minutes, please use the{" "}
            <em>{adminConsentButtonText}</em> button to try again in a few minutes. Otherwise please
            contact us so that we can investigate.
          </p>
        </InfoBox>
      )}

      <AttachedProjects projects={integration.attachedStacks} />
    </div>
  );
};

export default AzureIntegrationViewForm;
