import BillingMarketingTile from "../MarketingTile";
import { TALK_TO_SALES_LINK } from "../constants";

const CONFIG = {
  title: "Choose plan",
  subtitle: "We're here to help",
  description:
    "If the Starter plan didn't meet your expectations, we can help. Schedule a call with our team to discuss your goals and let us help you choose the plan tailored to your needs.",
  ctaText: "Talk to sales",
  ctaHref: TALK_TO_SALES_LINK,
};

const BillingCancelSubscriptionAnnouncement = () => {
  return <BillingMarketingTile data={CONFIG} />;
};

export default BillingCancelSubscriptionAnnouncement;
