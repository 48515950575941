import { AspectType } from "types/generated";
import Button from "ds/components/Button";
import { Download } from "components/icons/generated";

import { TimeFilter } from "../types";
import { getCSVDownloadLink } from "./utils";

type CSVDownloadButtonProps = {
  timeFilter: TimeFilter;
  aspectType: AspectType;
};

const CSVDownloadButton = ({ aspectType, timeFilter }: CSVDownloadButtonProps) => {
  const link = getCSVDownloadLink(aspectType, timeFilter);

  return (
    <Button to={link} target="_blank" variant="secondary" startIcon={Download} download>
      Export CSV
    </Button>
  );
};

export default CSVDownloadButton;
