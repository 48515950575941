import { ReactNode } from "react";

import Box from "ds/components/Box";
import Typography, { TypographyProps } from "ds/components/Typography";
import { Spacing } from "types/Spacing";

import BillingSummaryItem from "../SummaryItem";

type BillingSummaryItemsProps = {
  items: { label: ReactNode; value: ReactNode }[];
  labelVariant?: TypographyProps["variant"];
  valueVariant?: TypographyProps["variant"];
  gap: Spacing;
  fullWidth?: boolean;
  noTrailingDivider?: boolean;
  children?: ReactNode;
};

const BillingSummaryItems = ({
  items,
  gap = "x-large",
  labelVariant = "p-body2",
  valueVariant = "p-body2",
  fullWidth,
  noTrailingDivider = false,
  children,
}: BillingSummaryItemsProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Box direction="column" gap={gap} fullWidth={fullWidth}>
      {items.map(({ label, value }, index) => (
        <BillingSummaryItem
          key={index}
          paddingBottom={gap}
          label={
            typeof label === "string" ? (
              <Typography tag="span" variant={labelVariant}>
                {label}
              </Typography>
            ) : (
              label
            )
          }
          value={
            typeof value === "string" ? (
              <Typography tag="p" variant={valueVariant}>
                {value}
              </Typography>
            ) : (
              value
            )
          }
          noBorder={index === items.length - 1 && noTrailingDivider}
        />
      ))}
      {children}
    </Box>
  );
};

export default BillingSummaryItems;
