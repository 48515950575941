import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import { BillingInfo } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired, validateEmail } from "utils/formValidators";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import ButtonNew from "ds/components/Button/New";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { getDrawerFormFix } from "utils/css";

import { UPDATE_BILLING_INFO } from "./gql";
import BillingAddressFormFields from "../AddressFormFields";
import { InvoicingDetailsFields } from "../types";

type UpdateInvoicingDetailsDrawerProps = {
  billingInfo: BillingInfo;
};

const UpdateInvoicingDetailsDrawer = createDrawer(
  ({ billingInfo }: UpdateInvoicingDetailsDrawerProps) => {
    const drawer = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const form = useForm<InvoicingDetailsFields>({
      defaultValues: billingInfo,
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isValid, isDirty },
      getValues,
    } = form;

    const [updateBillingInfo, { loading }] = useMutation(UPDATE_BILLING_INFO, {
      refetchQueries: ["GetBilling"],
      awaitRefetchQueries: true,
    });

    useEffect(() => {
      if (billingInfo) {
        const currentValues = getValues();

        // TODO: update when react-hook-form upgraded
        reset({
          ...currentValues,
          ...billingInfo,
        });
      }
    }, [billingInfo, getValues, reset]);

    const onCloseWithConfirmation = useCallback(async () => {
      if (isDirty && !loading) {
        await showSimpleLeaveConfirmation();
      }
    }, [isDirty, loading]);

    const onSubmit = (formData: InvoicingDetailsFields) => {
      updateBillingInfo({
        variables: {
          input: formData,
        },
      })
        .then(() => {
          reportSuccess({
            message: `Invoicing details were successfully updated`,
          });
          drawer.hide();
        })
        .catch(onError);
    };

    return (
      <DrawerSimple onClose={onCloseWithConfirmation}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Invoicing details" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody gap="x-large" fullHeight>
              <FormField label="Full name" error={errors?.fullName?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="e.g. John Smith"
                    error={!!errors?.fullName}
                    {...register("fullName", {
                      validate: stringIsRequired("Full name is required."),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormField
                label="Email"
                error={errors?.email?.message}
                noMargin
                tooltipInfoVariant="modal"
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Email"
                    error={!!errors?.email}
                    {...register("email", {
                      required: "Email is required.",
                      validate: validateEmail,
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <BillingAddressFormFields />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <ButtonNew
                    variant="primary"
                    type="submit"
                    loading={loading}
                    disabled={!isValid || !isDirty || loading}
                  >
                    Update
                  </ButtonNew>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showUpdateInvoicingDetailsDrawer = createDrawerTrigger(UpdateInvoicingDetailsDrawer);
