import { BillingAddonType, BillingCycleInterval } from "types/generated";

import useStarterPlanInfo from "./useStarterPlanInfo";

const useSummaryItems = (interval: BillingCycleInterval, addons: BillingAddonType[]) => {
  const { subscription, addonsInfo } = useStarterPlanInfo(interval, addons);

  return [
    { label: "Plan fixed price", value: `$${subscription}` },
    ...addonsInfo.map((addon) => ({
      label: addon.label,
      value: `$${addon.value}`,
    })),
  ];
};

export default useSummaryItems;
