import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { z } from "zod";

import FullScreenModal from "ds/components/FullScreenModal";
import Button from "ds/components/Button";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";
import Callout from "ds/components/Callout";
import Typography from "ds/components/Typography";
import FeedbackActions from "ds/components/Feedback/Actions";
import { useLoadStripe } from "hooks/useLoadStripe";
import useURLParams from "hooks/useURLParams";
import { BillingCycleInterval } from "types/generated";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";

import BillingUpgradeSummary from "./Summary";
import BillingUpgradeDetails from "./Details";
import { UpgradePlanFields } from "./types";
import { BillingUpgradeContext } from "./context";
import { showBillingUpgradeSuccessModal } from "./SuccessModal";
import { URL_INTERVAL_PARAM } from "../constants";
import { getDefaultFormValues } from "./constants";

const BillingUpgrade = () => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Organization Settings · Billing · Upgrade to Starter · ${accountName}`);

  const navigate = useNavigate();
  const { stripe, error: stripeError } = useLoadStripe();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);

  const urlParams = useURLParams();
  const intervalValueFromURL = urlParams.get(URL_INTERVAL_PARAM);

  const initialIntervalValue = z
    .enum([BillingCycleInterval.Monthly, BillingCycleInterval.Yearly])
    .catch(BillingCycleInterval.Monthly)
    .parse(intervalValueFromURL);

  const [isSummary, setIsSummary] = useState(false);

  const handleContinue = useCallback(() => {
    if (!isSummary) {
      setIsSummary(true);
      return;
    }

    navigate("/settings/billing");
    showBillingUpgradeSuccessModal();
  }, [isSummary, navigate]);

  const form = useForm<UpgradePlanFields>({
    defaultValues: getDefaultFormValues(initialIntervalValue),
    mode: "onChange",
  });

  const {
    formState: { isDirty },
  } = form;

  const handleClose = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    navigate("/settings/billing");
  }, [isDirty, navigate]);

  const refreshPage = () => {
    window.location.reload();
  };

  const contextValue = useMemo(
    () => ({
      goToNextStep: handleContinue,
      goToPreviousStep: () => {
        if (isSummary) {
          setIsSummary(false);
        } else {
          handleClose();
        }
      },
      paymentMethod,
      setPaymentMethod: (paymentMethod: PaymentMethod | null) => setPaymentMethod(paymentMethod),
    }),
    [handleClose, handleContinue, isSummary, paymentMethod]
  );

  return (
    <Elements stripe={stripe}>
      <BillingUpgradeContext.Provider value={contextValue}>
        <FullScreenModal title="Upgrade to Starter" onClose={handleClose}>
          {stripeError ? (
            <Callout variant="danger" title="Payment method error">
              <Typography tag="p" variant="p-body3" margin="0 0 medium 0">
                Our payment platform failed to load. Please refresh the page or contact our support
                team.
              </Typography>
              <FeedbackActions>
                <Button size="small" variant="secondary" onClick={refreshPage}>
                  Refresh
                </Button>
              </FeedbackActions>
            </Callout>
          ) : null}
          <FormProvider {...form}>
            {isSummary ? <BillingUpgradeSummary /> : <BillingUpgradeDetails />}
          </FormProvider>
        </FullScreenModal>
      </BillingUpgradeContext.Provider>
    </Elements>
  );
};

export default BillingUpgrade;
