import { useQuery } from "@apollo/client";
import { Route, Routes } from "react-router-dom";
import { useMemo } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";
import { BillingAddonType, BillingTier } from "types/generated";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";

import { GET_BILLING } from "./gql";
import { GetBillingGql, V4BillingTier } from "./types";
import BillingDashboard from "./Dashboard";
import { BillingContext } from "./Context";
import BillingSubscriptionManagement from "./SubscriptionManagement";
import BillingPaymentFailureCallout from "./components/PaymentFailureCallout";
import BillingUpgrade from "./Upgrade";

const BillingNew = () => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Organization Settings · Billing · ${accountName}`);

  const { onError } = useTypedContext(FlashContext);
  const { error, loading, data } = useQuery<GetBillingGql>(GET_BILLING, {
    onError,
    fetchPolicy: "no-cache",
  });

  const ErrorContent = useErrorHandle(error);

  const currentAddons: BillingAddonType[] = useMemo(
    () => data?.billingSubscription?.addons?.map((addon) => addon.name) || [],

    [data?.billingSubscription?.addons]
  );

  if (ErrorContent) {
    return ErrorContent;
  }

  // TODO: maybe replace with skeleton loader
  if (!data && loading) {
    return <PageLoading />;
  }

  // TODO: replace with some empty or error state
  if (!data) {
    return <NotFoundPage />;
  }

  const hasActiveStarterSubscription =
    data.tier === BillingTier.V4Starter &&
    /**
     * TODO: We might get rid of isActive check soon and just rely on billingSubscription property
     * but for now it's needed to avoid unexpected behavior
     * here's the ticket for context:
     * https://app.clickup.com/t/869594bgx
     */
    !!data.billingSubscription?.isActive &&
    !data.billedExternally &&
    !data.onTrialUntil;

  const billingSubscription = hasActiveStarterSubscription ? data.billingSubscription : undefined;

  return (
    <BillingContext.Provider
      value={{
        tier: data.tier as V4BillingTier,
        billedExternally: data.billedExternally,
        billingSubscription,
        onTrialUntil: data.onTrialUntil,
        isTrial: !!data.onTrialUntil,
        currentAddons,
        availableBillingAddons: data.availableBillingAddons,
        availableSelfServicePlans: data.availableSelfServicePlans,
      }}
    >
      {!!billingSubscription && <BillingPaymentFailureCallout subscription={billingSubscription} />}

      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" fullWidth>
          <Box direction="row" align="center" gap="large" fullWidth>
            <ViewHeaderTitle tag="h2">Billing</ViewHeaderTitle>
          </Box>
        </ViewHeaderWrapper>
        {!!billingSubscription && (
          <ViewHeaderNavigation>
            <Tab end to="." label="Dashboard" />
            <Tab to="subscription" label="Subscription" />
          </ViewHeaderNavigation>
        )}
      </ViewHeader>
      <Routes>
        <Route index element={<BillingDashboard />} />
        {!!billingSubscription && (
          <Route
            path="subscription"
            element={<BillingSubscriptionManagement billingSubscription={billingSubscription} />}
          />
        )}
        <Route path="upgrade" element={<BillingUpgrade />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BillingContext.Provider>
  );
};

export default BillingNew;
