import { Popover, PopoverProps, Menu, SubmenuTrigger } from "react-aria-components";
import { ReactElement, ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type DropdownSubmenuProps = {
  children: ReactNode;
  triggerComponent: ReactElement;
  placement?: PopoverProps["placement"];
  className?: string;
};

const DropdownSubmenu = ({
  children,
  triggerComponent,
  placement = "bottom left",
  className,
}: DropdownSubmenuProps) => {
  return (
    <SubmenuTrigger>
      {triggerComponent}
      <Popover placement={placement} crossOffset={-5}>
        <Menu className={cx(styles.menu, className)}>{children}</Menu>
      </Popover>
    </SubmenuTrigger>
  );
};

export default DropdownSubmenu;
