import { useFormContext } from "react-hook-form";

import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";

import BilledFrom from "../../components/BilledFrom";
import { UpgradePlanFields } from "../types";
import BillingSummaryItem from "../../components/SummaryItem";
import BillingSummaryItems from "../../components/SummaryItems";
import useStarterPlanInfo from "../../hooks/useStarterPlanInfo";
import useSummaryItems from "../../hooks/useSummaryItems";

const BillSummary = () => {
  const { getValues } = useFormContext<UpgradePlanFields>();

  const { interval, addons } = getValues();

  const { total, subscriptionStartDate } = useStarterPlanInfo(interval, addons);

  const summaryItems = useSummaryItems(interval, addons);

  return (
    <TileWrapper direction="column" gap="x-large" grow="0">
      <Typography tag="span" variant="p-t5">
        Bill summary
      </Typography>
      <BillingSummaryItems gap="x-large" items={summaryItems} />
      <BillingSummaryItem
        paddingBottom="0"
        label={
          <Typography tag="span" variant="p-t5">
            Total
          </Typography>
        }
        value={
          <Typography tag="span" variant="p-t6">
            ${total}
          </Typography>
        }
        noBorder
      />
      <BilledFrom interval={interval} date={subscriptionStartDate} />
    </TileWrapper>
  );
};

export default BillSummary;
