import { Route, Routes } from "react-router-dom";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AzureIntegration } from "types/generated";
import { AccountContext } from "views/AccountWrapper";

import AzureIntegrationsList from "./List";
import AzureIntegrationView from "./View";
import AzureIntegrationEdit from "./Edit";

type AzureIntegrationsProps = {
  integrations: AzureIntegration[];
};

const AzureIntegrations = ({ integrations }: AzureIntegrationsProps) => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Azure integrations · ${accountName}`);

  return (
    <Routes>
      <Route
        path=":integrationId/edit"
        element={<AzureIntegrationEdit integrations={integrations} />}
      />

      <Route path=":integrationId" element={<AzureIntegrationView integrations={integrations} />} />

      <Route index element={<AzureIntegrationsList integrations={integrations} />} />
    </Routes>
  );
};

export default AzureIntegrations;
