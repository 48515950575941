import Typography from "ds/components/Typography";
import { BillingTier } from "types/generated";
import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import useTypedContext from "hooks/useTypedContext";

import BillingUpgradeOptionsCard from "./Card";
import BillingUpgradeOptionsStarterPlanCard from "./StarterPlanCard";
import { getNextBillingTiers } from "../utils";
import { BASE_INFO_BY_TIER } from "./constants";
import BillingUpgradeOptionsCardHorizontal from "./Card/Horizontal";
import { BillingContext } from "../Context";

const BillingUpgradeOptions = () => {
  const { isTrial, tier } = useTypedContext(BillingContext);
  const nextTiers = getNextBillingTiers(isTrial ? BillingTier.V4Free : tier);

  if (nextTiers.length === 0) {
    return null;
  }

  return (
    <TileWrapper padding="large x-large x-large x-large" gap="large" direction="column" grow="0">
      <Typography tag="h3" variant="p-t5">
        Upgrade options
      </Typography>

      <Box gap="x-large" fullWidth>
        {nextTiers.length === 1 ? (
          <BillingUpgradeOptionsCardHorizontal
            key={nextTiers[0]}
            tier={nextTiers[0]}
            title={BASE_INFO_BY_TIER[nextTiers[0]]?.title || ""}
            price={BASE_INFO_BY_TIER[nextTiers[0]]?.price || ""}
            description={BASE_INFO_BY_TIER[nextTiers[0]]?.description || ""}
          />
        ) : (
          nextTiers.map((nextTier) =>
            nextTier === BillingTier.V4Starter ? (
              <BillingUpgradeOptionsStarterPlanCard key={nextTier} />
            ) : (
              <BillingUpgradeOptionsCard
                key={nextTier}
                tier={nextTier}
                title={BASE_INFO_BY_TIER[nextTier]?.title || ""}
                price={BASE_INFO_BY_TIER[nextTier]?.price || ""}
                description={BASE_INFO_BY_TIER[nextTier]?.description || ""}
              />
            )
          )
        )}
      </Box>
    </TileWrapper>
  );
};

export default BillingUpgradeOptions;
