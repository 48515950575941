import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { BillingAddonType, BillingCycleInterval, BillingTier } from "types/generated";
import FormToggleField from "ds/components/Form/ToggleField";

import { ADDON_LABELS } from "../../constants";
import { formatPrice } from "../../utils";
import useAvailableAddons from "../../hooks/useAvailableAddons";

type BillingAddonFeatureTogglesProps = {
  interval: BillingCycleInterval;
  addons: BillingAddonType[];
  onChange: (addons: BillingAddonType[]) => void;
  disabled?: boolean;
};

const BillingAddonToggleFields = ({
  interval,
  addons,
  onChange,
  disabled,
}: BillingAddonFeatureTogglesProps) => {
  const handleAddonChange = (addon: BillingAddonType) => {
    onChange(addons.includes(addon) ? addons.filter((a) => a !== addon) : [...addons, addon]);
  };

  const availableAddons = useAvailableAddons(BillingTier.V4Starter);

  return (
    <Box direction="column" gap="x-large">
      <Typography tag="h6" variant="p-t5">
        Add ons
      </Typography>
      {availableAddons.map((addon) => (
        <FormToggleField
          key={addon.name}
          variant="switch"
          title={ADDON_LABELS[addon.name]}
          description={formatPrice(
            interval === BillingCycleInterval.Monthly ? addon.monthlyPrice : addon.yearlyPrice,
            interval
          )}
          checked={addons.includes(addon.name)}
          onChange={() => handleAddonChange(addon.name)}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};

export default BillingAddonToggleFields;
