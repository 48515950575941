import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import styles from "./styles/button.module.css";

type LinkButtonProps = {
  href: string;
  className?: string;
  full?: boolean;
  pill?: boolean;
  rel?: string;
  target?: string;
  children: React.ReactNode;
  disabled?: boolean;
};

/**
 * @deprecated use DS Button or DS Link instead
 */
const LinkButton = (props: LinkButtonProps) => {
  const { className, children, full, href, pill = false, disabled, ...otherProps } = props;

  const content = (
    <button
      type="button"
      className={cx(styles.button, styles["button--default"], className, {
        [styles["button--pill"]]: pill,
        [styles["button--full"]]: full,
      })}
    >
      {children}
    </button>
  );

  const linkProps = { href, ...otherProps };

  if (href.startsWith("http") || href.startsWith("mailto:")) {
    return (
      <a {...linkProps} className={cx(disabled && "link--disabled")}>
        {content}
      </a>
    );
  }

  return (
    <Link to={href} className={cx(disabled && "link--disabled")} {...otherProps}>
      {content}
    </Link>
  );
};

export default LinkButton;
