import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { BillingTier } from "types/generated";

import { BillingContext } from "../Context";

const useAvailableSelfServicePlan = (tier: BillingTier) => {
  const { availableSelfServicePlans } = useTypedContext(BillingContext);

  return useMemo(
    () =>
      availableSelfServicePlans
        .map((plan) => ({
          ...plan,
          monthlyPrice: plan.monthlyPrice / 100,
          yearlyPrice: plan.yearlyPrice / 100,
        }))
        .find((plan) => plan.tier === tier),
    [availableSelfServicePlans, tier]
  );
};

export default useAvailableSelfServicePlan;
