import { create, useModal, show } from "@ebay/nice-modal-react";

import Button from "ds/components/Button";
import Link from "ds/components/Link";
import { ConfettiColored } from "components/icons/generated";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import Heading from "ds/components/Typography/Heading";

import styles from "./styles.module.css";

const MAIL = "ar@spacelift.io";

const BillingUpgradeSuccessModal = create(function BillingUpgradeSuccessModal() {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} size="large" onExit={modal.remove}>
      <ModalContent>
        <Box direction="column" align="center" justify="center" padding="large">
          <ConfettiColored className={styles.icon} />
          <Heading variant="p-t3" className={styles.title}>
            Welcome Starter plan!
          </Heading>
          <Typography variant="p-body2" tag="p" className={styles.info}>
            Your plan is now active. If you have any subscription related questions please reach out
            to{" "}
            <Link href={`mailto:${MAIL}`} target="_blank">
              ar@spacelift.io
            </Link>
          </Typography>

          <Button variant="primary" onClick={modal.hide}>
            Okay
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export const showBillingUpgradeSuccessModal = () => show(BillingUpgradeSuccessModal);
