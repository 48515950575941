import { Navigate, Route, Routes } from "react-router-dom";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import UsageRunMinutes from "views/Account/Usage/RunMinutes";
import UsageWorkers from "views/Account/Usage/Workers";

const Usage = () => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · Usage · ${accountName}`);

  return (
    <Routes>
      <Route index element={<Navigate to="workers" replace />} />
      <Route path="runs" element={<UsageRunMinutes />} />
      <Route path="workers" element={<UsageWorkers />} />
    </Routes>
  );
};

export default Usage;
