import FeatureToggleTile from "components/FeatureToggleTile";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { BillingAddonType, BillingCycleInterval, BillingTier } from "types/generated";
import { User, DataFlow } from "components/icons/generated";

import { ADDON_LABELS } from "../../constants";
import { formatPrice } from "../../utils";
import useAvailableAddons from "../../hooks/useAvailableAddons";

type BillingAddonFeatureTogglesProps = {
  interval: BillingCycleInterval;
  addons: BillingAddonType[];
  onChange?: (addons: BillingAddonType[]) => void;
};

const getAddonIcon = (addon: BillingAddonType) => {
  switch (addon) {
    case BillingAddonType.UnlimitedSeats:
      return User;
    case BillingAddonType.AdditionalPublicWorker:
      return DataFlow;
  }
};

const getAddonBackground = (addon: BillingAddonType) => {
  switch (addon) {
    case BillingAddonType.UnlimitedSeats:
      return "purple";
    case BillingAddonType.AdditionalPublicWorker:
      return "orange";
  }
};

const BillingAddonFeatureToggles = ({
  interval,
  addons,
  onChange,
}: BillingAddonFeatureTogglesProps) => {
  const isReadonly = !onChange;
  const handleAddonChange = (addon: BillingAddonType, checked: boolean) => {
    onChange?.(checked ? [...addons, addon] : addons.filter((a) => a !== addon));
  };

  const availableAddons = useAvailableAddons(BillingTier.V4Starter);

  if (isReadonly && addons.length === 0) {
    return null;
  }

  return (
    <Box direction="column" gap="medium" margin="medium 0 0 0">
      <Typography tag="h6" variant="p-t6" color="secondary">
        Add ons
      </Typography>

      {availableAddons.map((addon) =>
        isReadonly && !addons.includes(addon.name) ? null : (
          <FeatureToggleTile
            key={addon.name}
            title={ADDON_LABELS[addon.name]}
            description={formatPrice(
              interval === BillingCycleInterval.Monthly ? addon.monthlyPrice : addon.yearlyPrice,
              interval
            )}
            icon={getAddonIcon(addon.name)}
            iconBackground={getAddonBackground(addon.name)}
            checked={addons.includes(addon.name)}
            onChange={onChange ? (checked) => handleAddonChange(addon.name, checked) : undefined}
          />
        )
      )}
    </Box>
  );
};

export default BillingAddonFeatureToggles;
