import { create, show, useModal } from "@ebay/nice-modal-react";

import { TIER_PLANS_LABELS } from "constants/tiers";
import InfoBox from "components/InfoBox";
import { BillingTier } from "types/generated";
import ModalContent from "ds/components/Modal/Content";
import ModalHeader from "ds/components/Modal/Header";
import ModalSimple from "ds/components/ModalSimple";
import Box from "ds/components/Box";

import UpdateButton from "../UpgradeButton";
import styles from "./modal.module.css";

type BillingSubscriptionDetailsUpgradeModalProps = {
  tier: BillingTier;
};

const BillingSubscriptionDetailsUpgradeModal = create(
  function BillingSubscriptionDetailsUpgradeModal(
    props: BillingSubscriptionDetailsUpgradeModalProps
  ) {
    const { tier } = props;

    const modal = useModal();

    return (
      <ModalSimple size="large">
        <ModalHeader title="Change plan" />
        <ModalContent align="center">
          <Box direction="column" align="stretch" fullWidth>
            <InfoBox>
              Your current plan is
              <span className={styles.tier}>{`${TIER_PLANS_LABELS[tier]}`}</span>
            </InfoBox>
          </Box>
          <UpdateButton tier={tier} onModalClose={modal.hide} />
          <p>
            You can check plan details{" "}
            <a
              href="https://spacelift.io/pricing"
              rel="noopener noreferrer"
              className={styles.link}
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </ModalContent>
      </ModalSimple>
    );
  }
);

export const showBillingUpgradeModal = (props: BillingSubscriptionDetailsUpgradeModalProps) =>
  show(BillingSubscriptionDetailsUpgradeModal, props);
