import { ReactNode } from "react";

import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { TypographyColors } from "types/Colors";
import Button from "ds/components/Button";
import Link from "ds/components/Link";
import Icon from "ds/components/Icon";
import { External } from "components/icons/generated";
import { TIER_PLANS_LABELS } from "constants/tiers";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";

import { ADDITIONAL_FEATURES_BY_TIER, DETAILS_BY_TIER } from "../constants";
import BillingFeatureItem from "../../components/FeatureItem";
import styles from "./styles.module.css";
import { getPreviousBillingTier } from "../../utils";
import BillingTierBadge from "../../components/TierBadge";
import { V4BillingTier } from "../../types";
import { TALK_TO_SALES_LINK } from "../../constants";

type BillingUpgradeOptionsCardProps = {
  tier: V4BillingTier;
  title?: string;
  titleColor?: TypographyColors;
  price: string;
  description: string;
  action?: ReactNode;
  ctaButton?: ReactNode;
  className?: string;
};

const BillingUpgradeOptionsCardHorizontal = ({
  tier,
  title = "Starting from",
  titleColor = "secondary",
  price,
  description,
  action,
  ctaButton,
  className,
}: BillingUpgradeOptionsCardProps) => {
  const details = DETAILS_BY_TIER[tier];

  const previousTier = getPreviousBillingTier(tier);

  const previousTierLabel = TIER_PLANS_LABELS[previousTier];

  const ctaButtonTitle = "Talk to sales";

  return (
    <TileWrapper grid gridTemplate="1fr auto 1fr" gap="x-large" className={className}>
      <Box direction="column" gap="x-large" align="start">
        <Box justify="between" gap="large" fullWidth>
          <BillingTierBadge tier={tier} />
          {action}
        </Box>
        <Box direction="column" gap="medium">
          <Typography tag="p" variant="p-t8" color={titleColor}>
            {title}
          </Typography>
          <Typography tag="p" variant="p-t2">
            {price}
          </Typography>
          <Typography tag="p" variant="p-body4" color="secondary">
            {description}
          </Typography>
        </Box>

        <Box gap="medium">
          {details?.map(({ text, icon }, index) => (
            <BillingFeatureItem key={index} text={text} iconComponent={icon} />
          ))}
        </Box>
        {ctaButton || (
          <Button
            variant="secondary"
            href={TALK_TO_SALES_LINK}
            target="_blank"
            size="small"
            analyticsPage={AnalyticsPageBilling.BillingDashboard}
            analyticsTitle="Upgrade Options CTA Button Clicked"
            analyticsProps={{
              ctaButtonTitle,
              plan: tier,
            }}
          >
            {ctaButtonTitle}
          </Button>
        )}
      </Box>

      <hr className={styles.dividerVertical} />
      <Box direction="column" gap="x-large" align="start" fullWidth>
        <Box direction="column" gap="medium" fullWidth>
          <Box justify="between" align="center" gap="large" fullWidth>
            <Typography tag="p" variant="p-t7">
              All from {previousTierLabel} and
            </Typography>
          </Box>
          {ADDITIONAL_FEATURES_BY_TIER[tier]?.map((feature, index) => (
            <BillingFeatureItem key={index} text={feature.text} infoTooltip={feature.infoTooltip} />
          ))}
        </Box>

        <Link
          target="_blank"
          href="https://spacelift.io/pricing"
          align="center"
          gap="small"
          analyticsPage={AnalyticsPageBilling.BillingDashboard}
          analyticsTitle="Upgrade Options - See All Link Clicked"
          analyticsProps={{
            plan: tier,
          }}
        >
          <Typography tag="span" variant="p-body3">
            See all
          </Typography>
          <Icon src={External} />
        </Link>
      </Box>
    </TileWrapper>
  );
};

export default BillingUpgradeOptionsCardHorizontal;
