import { BillingAddonType, BillingCycleInterval, BillingTier } from "types/generated";

import useAvailableAddons from "./useAvailableAddons";
import { ADDON_LABELS } from "../constants";
import useAvailableSelfServicePlan from "./useAvailableSelfServicePlan";

const useStarterPlanInfo = (interval: BillingCycleInterval, addons: BillingAddonType[] = []) => {
  const availableAddons = useAvailableAddons(BillingTier.V4Starter);

  const starterPlanInfo = useAvailableSelfServicePlan(BillingTier.V4Starter);

  const addonsInfo = availableAddons
    .filter((addon) => addons?.includes(addon.name))
    .map((addon) => ({
      label: ADDON_LABELS[addon.name],
      value: interval === BillingCycleInterval.Monthly ? addon.monthlyPrice : addon.yearlyPrice,
    }));

  const monthlyPrice = starterPlanInfo?.monthlyPrice ?? 0;
  const annualPrice = starterPlanInfo?.yearlyPrice ?? 0;

  const subscription = interval === BillingCycleInterval.Monthly ? monthlyPrice : annualPrice;

  const total = subscription + addonsInfo.reduce((acc, addon) => acc + addon.value, 0);

  const subscriptionStartDate = new Date();

  return { subscription, total, monthlyPrice, annualPrice, subscriptionStartDate, addonsInfo };
};

export default useStarterPlanInfo;
