import TextLink from "components/DocumentationSnippets/TextLink";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

const DefaultTermsAndConditions = () => (
  <Typography tag="p" variant="p-body4" color="secondary">
    By clicking on the button you agree to our{" "}
    <TextLink href={getDocsUrl("/legal/terms")} inheritFont>
      Terms and Conditions
    </TextLink>{" "}
    and authorize the recurring charge. You can cancel the subscription at any time in you billing
    settings.To check how we process your data, take a look at our{" "}
    <TextLink href={getDocsUrl("/legal/privacy")} inheritFont>
      Privacy Policy
    </TextLink>
    .
  </Typography>
);

export default DefaultTermsAndConditions;
