import { useMemo } from "react";

import { BillingAddonType, BillingCycleInterval, BillingTier } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import { STARTER_ANNUAL_DISCOUNT, TALK_TO_SALES_LINK } from "../constants";
import { BillingContext } from "../Context";
import { MarketingTileData } from "./types";

const getStarterInfo = (
  interval?: BillingCycleInterval | null,
  addons?: BillingAddonType[] | null,
  isBilledExternally: boolean = false
): MarketingTileData => {
  if (!isBilledExternally && interval && interval === BillingCycleInterval.Monthly) {
    return {
      title: "Switch to Annual",
      subtitle: `Save up to ${STARTER_ANNUAL_DISCOUNT}%`,
      description: `Save up to ${STARTER_ANNUAL_DISCOUNT}% and gain 2 months for free by upgrading to annual Starter plan.`,
      ctaText: "Upgrade",
      ctaTo: "/settings/billing/subscription",
    };
  }

  if (!isBilledExternally && addons && addons.length === 0) {
    return {
      title: "Choose Add-ons",
      subtitle: "Running out of workers or seats?",
      description:
        "Add unlimited users or an additional private worker to your subscription for a seamless workflow!",
      ctaText: "Manage plan",
      ctaTo: "/settings/billing/subscription",
    };
  }

  return {
    title: "Switch to Starter Plus",
    subtitle: "Ready to Grow?",
    description:
      "Upgrade to Starter Plus for unlimited users, a private worker, and drift detection to help you automate your infrastructure pipelines.",
    ctaText: "Talk to sales",
    ctaHref: TALK_TO_SALES_LINK,
  };
};

const useMarketingTileData = () => {
  const { tier, billedExternally, billingSubscription, isTrial, currentAddons } =
    useTypedContext(BillingContext);

  const finalTier = isTrial ? BillingTier.V4Free : tier;

  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (finalTier) {
      case BillingTier.V4Free:
        return {
          title: "Upgrade to Starter Plan",
          subtitle: `Save up to ${STARTER_ANNUAL_DISCOUNT}%`,
          description: `Save up to ${STARTER_ANNUAL_DISCOUNT}% and gain 2 months for free by upgrading to annual Starter plan.`,
          /** This fallback might not be needed, cause unlikely we'll have a free tier with external billing
           * But currently this is possible to do via back office bot, so keeping it for now until clarified */
          ctaText: billedExternally ? "Talk to sales" : "Upgrade",
          ctaTo: billedExternally ? undefined : "/settings/billing/upgrade",
          ctaHref: billedExternally ? TALK_TO_SALES_LINK : undefined,
        };
      case BillingTier.V4Starter:
        return getStarterInfo(
          billingSubscription?.billingInterval,
          currentAddons,
          billedExternally
        );
      case BillingTier.V4StarterPlus:
        return {
          title: "Switch to Business",
          subtitle: "Looking for more?",
          description:
            "Switch to Business for more private workers and premium support to take your infrastructure orchestration to the next level.",
          ctaText: "Talk to sales",
          ctaHref: TALK_TO_SALES_LINK,
        };
      case BillingTier.V4Business:
        return {
          title: "Switch to Enterprise",
          subtitle: "Outgrowing your plan?",
          description:
            "Upgrade to Enterprise for enhanced security, concurrent VCS connections and advanced audit capabilities for your growing business.",
          ctaText: "Talk to sales",
          ctaHref: TALK_TO_SALES_LINK,
        };
      default:
        return null;
    }
  }, [finalTier, billedExternally, billingSubscription?.billingInterval, currentAddons]);
};

export default useMarketingTileData;
