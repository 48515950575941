import { GroupByKey } from "./constants";

export const getLegendTitleByGroup = (groupBy?: GroupByKey) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (groupBy) {
    case GroupByKey.StackSlug:
      return "Stack colors coding:";
    case GroupByKey.RunState:
      return "Run states colors coding:";
    case GroupByKey.RunType:
      return "Run types colors coding:";
    case GroupByKey.WorkerPoolSlug:
      return "Worker pools colors coding:";
    default:
      return "";
  }
};
