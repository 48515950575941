import { ReactNode } from "react";

import { Rocket } from "components/icons/generated";
import FeedbackHeadline from "ds/components/Feedback/Headline";
import FeedbackWrapper from "ds/components/Feedback/Wrapper";
import Typography from "ds/components/Typography";
import Box, { BoxProps } from "ds/components/Box";

import { STARTER_ANNUAL_DISCOUNT } from "../../constants";

type BillingDiscountBannerProps = {
  title?: string;
  description?: string;
  action?: ReactNode;
  margin?: BoxProps["margin"];
};

const DEFAULT_TITLE = `Save  ${STARTER_ANNUAL_DISCOUNT}%`;
const DEFAULT_DESCRIPTION = `Switch to annual subscription and get ${STARTER_ANNUAL_DISCOUNT}% discount.`;

const BillingDiscountBanner = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  action,
  margin,
}: BillingDiscountBannerProps) => {
  return (
    <FeedbackWrapper type="banner" variant="success" icon={Rocket} margin={margin}>
      <Box justify="between" align="start" gap="large">
        <Box direction="column">
          <FeedbackHeadline>{title}</FeedbackHeadline>
          <Typography variant="p-body3" tag="p">
            {description}
          </Typography>
        </Box>
        {action}
      </Box>
    </FeedbackWrapper>
  );
};

export default BillingDiscountBanner;
