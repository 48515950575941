import { BillingAddonType, BillingCycleInterval } from "types/generated";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";

import styles from "./styles.module.css";
import BillingSummaryItem from "../../components/SummaryItem";
import BillingSummaryItems from "../../components/SummaryItems";
import useSummaryItems from "../../hooks/useSummaryItems";
import useStarterPlanInfo from "../../hooks/useStarterPlanInfo";
import { formatPrice } from "../../utils";

type BillingTierTilePricingBreakdownProps = {
  billingInterval: BillingCycleInterval;
  addons: BillingAddonType[];
};

const BillingTierTilePricingBreakdown = ({
  billingInterval,
  addons,
}: BillingTierTilePricingBreakdownProps) => {
  const planInfo = useStarterPlanInfo(billingInterval, addons);
  const summaryItems = useSummaryItems(billingInterval, addons);

  return (
    <>
      <Typography tag="p" variant="p-t4" color="secondary">
        {formatPrice(planInfo.total, billingInterval, true)}
      </Typography>
      {summaryItems.length > 1 && (
        <Box direction="column" gap="medium" className={styles.pricingBreakdown}>
          <BillingSummaryItem
            label={
              <Typography tag="span" variant="p-t7">
                Pricing breakdown
              </Typography>
            }
            value={null}
            paddingBottom="medium"
          />
          <BillingSummaryItems
            gap="small"
            labelVariant="p-body3"
            items={summaryItems}
            noTrailingDivider
          />
        </Box>
      )}
    </>
  );
};

export default BillingTierTilePricingBreakdown;
