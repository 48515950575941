import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import FormFieldViewText from "components/FormFields/ViewText";
import { TIER_PLANS_LABELS } from "constants/tiers";
import useTypedContext from "hooks/useTypedContext";
import { BillingAddonType, BillingCycleInterval, BillingTier } from "types/generated";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import FlashContext from "components/FlashMessages/FlashContext";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import ButtonNew from "ds/components/Button/New";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import BillingSummaryItems from "../../components/SummaryItems";
import BilledFrom from "../../components/BilledFrom";
import PaymentsPartner from "../../components/PaymentsPartner";
import { STARTER_ANNUAL_DISCOUNT } from "../../constants";
import BillingSubscriptionManagementTermsAndConditions from "../TermsAndConditions";
import { UPDATE_BILLING_SUBSCRIPTION } from "../gql";

type BillingSubscriptionManagementSummaryDrawerProps = {
  interval: BillingCycleInterval;
  tier: BillingTier;
  addons: BillingAddonType[];
  summaryItems: { label: string; value: string }[];
  total: number;
};

const BillingSubscriptionManagementSummaryDrawer = createDrawer(
  ({
    tier,
    interval,
    addons,
    summaryItems,
    total,
  }: BillingSubscriptionManagementSummaryDrawerProps) => {
    const drawer = useModal();
    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const [updateSubscription, { loading }] = useMutation(UPDATE_BILLING_SUBSCRIPTION, {
      refetchQueries: ["GetBilling", "GetTier", "GetAccountSettings"],
      variables: { input: { interval, tier, addons } },
      awaitRefetchQueries: true,
      onError,
      fetchPolicy: "no-cache",
    });

    const handleSubmit = () =>
      updateSubscription()
        .then((result) => {
          if (!result.errors) {
            reportSuccess({ message: "Subscription updated" });
            drawer.hide();
          }
        })
        .catch(onError);

    return (
      <DrawerSimple isDismissable={false}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Update subscription" />
        </DrawerHeader>
        <DrawerBody gap="x-large" hasDivider>
          <FormFieldViewText label="Plan type" value={TIER_PLANS_LABELS[tier]} />
          <Box justify="between" align="end">
            <FormFieldViewText
              label="Billing cycle"
              value={interval === BillingCycleInterval.Monthly ? "Monthly" : "Annual"}
            />
            {interval === BillingCycleInterval.Yearly && (
              <BadgeNext
                variant="green"
                text={`${STARTER_ANNUAL_DISCOUNT}% discount applied`}
                type="weak"
              />
            )}
          </Box>
        </DrawerBody>
        <DrawerBody gap="x-large">
          <Typography tag="p" variant="p-t5">
            Bill summary
          </Typography>
          <Box direction="column">
            <BillingSummaryItems items={summaryItems} gap="x-large" />
            <Box padding="large 0 0 0" justify="between" align="center">
              <Typography tag="p" variant="p-t5">
                Total
              </Typography>
              <Typography tag="p" variant="p-t6">
                ${total}
              </Typography>
            </Box>
          </Box>
          <Box padding="0 0 medium 0">
            <BilledFrom interval={interval} date={new Date()} />
          </Box>
          <PaymentsPartner
            termsAndConditions={<BillingSubscriptionManagementTermsAndConditions />}
          />
        </DrawerBody>
        <DrawerFooter>
          <DrawerFooterActions>
            <DrawerCancelButton disabled={loading} />
            <ButtonNew
              variant="primary"
              onPress={handleSubmit}
              loading={loading}
              disabled={loading}
            >
              Update subscription
            </ButtonNew>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerSimple>
    );
  }
);

export const showBillingSubscriptionManagementSummaryDrawer = createDrawerTrigger(
  BillingSubscriptionManagementSummaryDrawer
);
