import RCSlider, { SliderProps } from "rc-slider";
import cx from "classnames";
import { useEffect, useRef } from "react";

import styles from "./styles.module.css";
import Tooltip from "../Tooltip";

type RangeProps = {
  value: number;
  max: number;
  min: number;
  onChange: (value: number) => void;
  onChangeComplete?: (value: number) => void;
  step?: number;
  disabled?: boolean;
  hasError?: boolean;
  tooltip?: boolean;
  "aria-labelledby"?: string;
};

const Slider = ({
  value,
  min,
  max,
  step = 1,
  onChange,
  onChangeComplete,
  disabled,
  hasError,
  tooltip,
  "aria-labelledby": ariaLabelledBy,
}: RangeProps) => {
  const timeout = useRef<NodeJS.Timeout>();

  const handleChange = (value: number | number[]) => {
    if (typeof value === "number") {
      onChange(value);
    }
  };

  const handleChangeComplete = (value: number | number[]) => {
    if (onChangeComplete && typeof value === "number") {
      onChangeComplete(value);
    }
  };

  const changeCompleteDebouncedValue = (value: number) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      handleChangeComplete(value);
    }, 300);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.code === "ArrowLeft" && value > min) {
      const newValue = value - 1;
      handleChange(newValue);
      changeCompleteDebouncedValue(newValue);
    }

    if (e.code === "ArrowRight" && value < max) {
      const newValue = value + 1;
      handleChange(newValue);
      changeCompleteDebouncedValue(newValue);
    }
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const tipHandleRender: SliderProps["handleRender"] = (_, handleProps) => (
    <span
      className={cx(styles.handle, handleProps.dragging && styles.dragging)}
      tabIndex={0}
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={handleProps.value}
      aria-disabled={disabled}
      aria-orientation="horizontal"
      aria-labelledby={ariaLabelledBy}
      onKeyDown={onKeyDown}
      style={{
        left: `${(handleProps.value * 100) / (max - min)}%`,
        transform: "translateX(-50%)",
      }}
    >
      <Tooltip
        active={!!tooltip}
        forceOpen={handleProps.dragging}
        mode="parent"
        on={(tooltipProps) => <span className={styles.artificialTooltip} {...tooltipProps} />}
      >
        {handleProps.value}
      </Tooltip>
    </span>
  );

  return (
    <RCSlider
      disabled={disabled}
      value={value}
      marks={{
        [min]: {
          style: {
            font: "var(--typography-p-body-4)",
            transform: "none",
            color: "var(--typography-secondary-text-color)",
          },
          label: min.toString(),
        },
        [max]: {
          style: {
            color: "var(--typography-secondary-text-color)",
            font: "var(--typography-p-body-4)",
            transform: "translateX(-100%)",
          },
          label: max.toString(),
        },
      }}
      className={cx(styles.slider, hasError && styles.error, disabled && styles.disabled)}
      classNames={{
        handle: styles.handle,
        tracks: styles.tracks,
        track: styles.track,
        rail: styles.rail,
      }}
      min={min}
      max={max}
      step={step}
      onChange={handleChange}
      dotStyle={{
        display: "none",
      }}
      onChangeComplete={handleChangeComplete}
      handleRender={tipHandleRender}
    />
  );
};

export default Slider;
