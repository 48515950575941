import { Controller, FormProvider, useForm } from "react-hook-form";
import { useCallback } from "react";

import Box from "ds/components/Box";
import SpaceliftLogo from "ds/components/SpaceliftLogo";
import Typography from "ds/components/Typography";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Input from "ds/components/Input";
import ButtonNew from "ds/components/Button/New";
import { validateEmail } from "utils/formValidators";
import { createDefaultMutationHook } from "hooks/useMutationHandler";

import styles from "./styles.module.css";
import { COMPANY_SIZE_OPTIONS, TYPE_OF_USE_OPTIONS, WORK_OPTIONS } from "./constants";
import { ProductSignUpFormFields } from "./types";
import { SUBMIT_PSU_FORM } from "./gql";

const useSubmitPSUForm = createDefaultMutationHook(SUBMIT_PSU_FORM);

const ProductSignUpForm = () => {
  const productSignUpForm = useForm<ProductSignUpFormFields>({
    defaultValues: {
      typeOfUse: "Business",
    },
    mode: "onSubmit",
    shouldUnregister: true,
  });

  const [submitPSUForm, { loading }] = useSubmitPSUForm({
    refetchQueries: ["GetAccount"],
    awaitRefetchQueries: true,
  });

  const { control, watch, handleSubmit } = productSignUpForm;

  const currentTypeOfUse = watch("typeOfUse");
  const currentJob = watch("job");

  const isBusiness = currentTypeOfUse === "Business";

  const isOtherJob = currentJob === "Other";

  const prepareSubmitData = useCallback(
    async (data: ProductSignUpFormFields) => {
      const { jobOther, ...fields } = data;
      const preparedData = { ...fields };

      if (jobOther) {
        preparedData.job = jobOther;
      }

      submitPSUForm({ data: JSON.stringify(preparedData) });
    },
    [submitPSUForm]
  );

  return (
    <FormProvider {...productSignUpForm}>
      <Box grid gridTemplate="1fr 2fr" direction="row" className={styles.pageWrapper}>
        <Box padding="xx-large" className={styles.leftSide} direction="column">
          <SpaceliftLogo className={styles.logo} />
        </Box>
        <Box className={styles.mainContent} direction="column">
          <form className={styles.form} onSubmit={handleSubmit(prepareSubmitData)}>
            <Box gap="x-large" direction="column">
              <Typography variant="p-t2" tag="h1" margin="0 0 xx-large">
                Welcome to Spacelift!
              </Typography>
              <Controller
                name="typeOfUse"
                control={control}
                render={({ field }) => (
                  <FormField
                    label="What will you use Spacelift for?"
                    helperText="Do you intend using Spacelift for professional or personal use?"
                    noMargin
                  >
                    {({ ariaInputProps }) => (
                      <Select
                        value={field.value}
                        options={TYPE_OF_USE_OPTIONS}
                        onChange={field.onChange}
                        ariaInputProps={ariaInputProps}
                      />
                    )}
                  </FormField>
                )}
              />
              {isBusiness && (
                <Controller
                  name="workEmail"
                  control={control}
                  rules={{ required: "Work email is required", validate: validateEmail }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label="Work email"
                      helperText="Please provide us with your work email "
                      error={fieldState.error?.message}
                      noMargin
                    >
                      {({ ariaInputProps }) => (
                        <Input
                          error={!!fieldState.error}
                          type="text"
                          value={field.value}
                          placeholder="Enter email"
                          onChange={field.onChange}
                          {...ariaInputProps}
                        />
                      )}
                    </FormField>
                  )}
                />
              )}
              <Controller
                name="job"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field, fieldState }) => (
                  <FormField
                    label="What kind of work do you do?"
                    helperText="What best describes your work?"
                    error={fieldState.error?.message}
                    noMargin
                  >
                    {({ ariaInputProps }) => (
                      <Select
                        value={field.value}
                        options={WORK_OPTIONS}
                        onChange={field.onChange}
                        error={!!fieldState.error?.message}
                        ariaInputProps={ariaInputProps}
                      />
                    )}
                  </FormField>
                )}
              />
              {isOtherJob && (
                <Controller
                  name="jobOther"
                  control={control}
                  rules={{ required: "This is required" }}
                  render={({ field, fieldState }) => (
                    <FormField error={fieldState.error?.message} noMargin>
                      {({ ariaInputProps }) => (
                        <Input
                          error={!!fieldState.error}
                          type="text"
                          value={field.value}
                          placeholder="Project Manager"
                          onChange={field.onChange}
                          {...ariaInputProps}
                        />
                      )}
                    </FormField>
                  )}
                />
              )}
              <Controller
                name="companySize"
                control={control}
                rules={{ required: "Company size is required" }}
                render={({ field, fieldState }) => (
                  <FormField
                    label="Company size"
                    helperText="How many people are working in your company?"
                    error={fieldState.error?.message}
                    noMargin
                  >
                    {({ ariaInputProps }) => (
                      <Select
                        value={field.value}
                        options={COMPANY_SIZE_OPTIONS}
                        onChange={field.onChange}
                        error={!!fieldState.error?.message}
                        ariaInputProps={ariaInputProps}
                      />
                    )}
                  </FormField>
                )}
              />
              <Box justify="end">
                <ButtonNew variant="primary" type="submit" loading={loading} disabled={loading}>
                  Get started!
                </ButtonNew>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default ProductSignUpForm;
