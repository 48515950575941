import { create, show, useModal } from "@ebay/nice-modal-react";

import { TIER_PLANS_LABELS } from "constants/tiers";
import WarningBar from "components/warning/WarningBar";
import { BillingTier } from "types/generated";
import ModalSimple from "ds/components/ModalSimple";
import ModalHeader from "ds/components/Modal/Header";
import ModalContent from "ds/components/Modal/Content";

import DeleteButton from "../DeleteButton";
import styles from "./modal.module.css";

type BillingSubscriptionDetailsCancelModalProps = {
  tier: BillingTier;
};

const BillingSubscriptionDetailsCancelModal = create(function BillingSubscriptionDetailsCancelModal(
  props: BillingSubscriptionDetailsCancelModalProps
) {
  const { tier } = props;

  const modal = useModal();

  return (
    <ModalSimple size="large">
      <ModalHeader title="Subscription cancelling" />
      <ModalContent align="center">
        <WarningBar>
          Your current plan is
          <span className={styles.tier}>{`${TIER_PLANS_LABELS[tier]}`}</span>. By clicking button
          below you change subscription plan to Free, some of the features will be limited.
        </WarningBar>
        <DeleteButton onModalClose={modal.hide} />
        <p>
          You can check plan details{" "}
          <a
            href="https://spacelift.io/pricing"
            className={styles.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </ModalContent>
    </ModalSimple>
  );
});

export const showBillingCancelationModal = (props: BillingSubscriptionDetailsCancelModalProps) =>
  show(BillingSubscriptionDetailsCancelModal, props);
