import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { BillingTier } from "types/generated";

import { BillingContext } from "../Context";

const useAvailableAddons = (tier: BillingTier) => {
  const { availableBillingAddons } = useTypedContext(BillingContext);

  return useMemo(
    () =>
      availableBillingAddons
        .filter((addon) => addon.tiers.includes(tier))
        .map((addon) => ({
          ...addon,
          monthlyPrice: addon.monthlyPrice / 100,
          yearlyPrice: addon.yearlyPrice / 100,
        })),
    [availableBillingAddons, tier]
  );
};

export default useAvailableAddons;
