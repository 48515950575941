import { useEffect, useState } from "react";

import { ChevronRight, ThumbUp, External } from "components/icons/generated";
import { LayoutContext } from "components/layout/Context";
import useTypedContext from "hooks/useTypedContext";
import useInitFeaturebase from "hooks/useInitFeaturebase";
import Icon from "ds/components/Icon";
import ListItemAriaAction from "ds/components/ListItemAction/ListItemAriaAction";
import { getDocsUrl } from "utils/getDocsUrl";
import Tooltip from "ds/components/Tooltip";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import DropdownMenuSection from "ds/components/DropdownMenu/Section";
import DropdownMenuSectionHeader from "ds/components/DropdownMenu/Section/Header";
import DropdownMenuDivider from "ds/components/DropdownMenu/Divider";
import Box from "ds/components/Box";

import FadeTransition from "../FadeTransition";
import MenuItemText from "../MenuItemText";
import styles from "./styles.module.css";
import { FEATUREBASE_URL } from "../FeaturebaseSH/consts";

const HelpSection = () => {
  const { isExpandedMode: isExpanded } = useTypedContext(LayoutContext);
  const [isOpen, setIsOpen] = useState(false);

  useInitFeaturebase();

  useEffect(() => {
    if (window.Pylon) {
      window.Pylon("onChangeUnreadMessagesCount", function (unreadCount) {
        // we want to show chat when there is unread message
        if (unreadCount > 0) {
          window.Pylon("show");
        }
      });
    }
  }, []);

  const handleOpenWidget = () => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "openFeedbackWidget",
      },
    });
  };

  const handleOpenSupportChat = () => {
    if (window.Pylon) {
      window.Pylon("show");
    }

    setIsOpen(false);
  };

  return (
    <DropdownMenu
      className={styles.dropdown}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      placement="right bottom"
      triggerComponent={
        <Tooltip
          active={!isExpanded}
          placement="right"
          on={({ ref: tooltipRef, ...props }) => (
            <ListItemAriaAction
              innerRef={tooltipRef}
              className={styles.dropdownButton}
              icon={ThumbUp}
              active={isOpen}
              id="beamerButton"
              aria-label="Help & Feedback"
              data-beamer-click="false"
              {...props}
            >
              <FadeTransition visible={isExpanded}>
                <>
                  <MenuItemText>Help & Feedback</MenuItemText>
                  <Icon src={ChevronRight} noShrink />
                </>
              </FadeTransition>
            </ListItemAriaAction>
          )}
        >
          Help & Feedback
        </Tooltip>
      }
    >
      <DropdownMenuSection>
        <DropdownMenuSectionHeader>Help & resources</DropdownMenuSectionHeader>
        <DropdownMenuItem
          className={styles.dropdownItem}
          isDisabled={!window.Beamer}
          onAction={() => window.Beamer?.show()}
        >
          <Box id="beamerButtonOpen" fullWidth>
            Product updates
          </Box>
        </DropdownMenuItem>
        <DropdownMenuItem className={styles.dropdownItem} onAction={handleOpenSupportChat}>
          Support chat
        </DropdownMenuItem>
        <DropdownMenuItem className={styles.dropdownItem} href={getDocsUrl("")} target="_blank">
          Documentation
          <Icon src={External} />
        </DropdownMenuItem>
      </DropdownMenuSection>
      <DropdownMenuDivider />
      <DropdownMenuSection>
        <DropdownMenuSectionHeader>Feedback</DropdownMenuSectionHeader>
        <DropdownMenuItem className={styles.dropdownItem} onAction={handleOpenWidget}>
          Request a feature
        </DropdownMenuItem>
        <DropdownMenuItem
          className={styles.dropdownItem}
          data-featurebase-link // see: https://help.featurebase.app/help/articles/2796697-install-featurebase
          href={FEATUREBASE_URL}
          target="_blank"
        >
          Feedback dashboard
        </DropdownMenuItem>
      </DropdownMenuSection>
    </DropdownMenu>
  );
};

export default HelpSection;
