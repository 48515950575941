import { TIER_PLANS_LABELS } from "constants/tiers";
import Box from "ds/components/Box";
import ProgressBar from "ds/components/ProgressBar";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import { BillingTier } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import { formatBillingDate } from "../utils";
import styles from "./styles.module.css";
import { TRIAL_DAYS_COUNT } from "../constants";
import { BillingContext } from "../Context";
import BillingSubscriptionStatusBadge from "../components/SubscriptionStatusBadge";
import { StripeSubscriptionStatus } from "../types";
import useTrialTime from "./useTrialTime";
import BillingTierTilePricingBreakdown from "./PricingBreakdown";

const BillingTierTile = () => {
  const { tier, billedExternally, billingSubscription, onTrialUntil, currentAddons } =
    useTypedContext(BillingContext);

  const { isTrialFinished, trialDaysLeft, distanceInDays } = useTrialTime(onTrialUntil);

  return (
    <TileWrapper direction="column" justify="between">
      <Box direction="column" fullWidth>
        <Box gap="medium" align="center" justify="between">
          <Box gap="medium" align="center" justify="between">
            <Typography tag="h3" variant="p-t5">
              Your plan
            </Typography>
            {billingSubscription && (
              <>
                <BillingSubscriptionStatusBadge
                  status={billingSubscription.status as StripeSubscriptionStatus}
                />

                {billingSubscription.billingRenewalDate && (
                  <Typography tag="p" variant="p-t7">
                    Next renewal: {formatBillingDate(billingSubscription.billingRenewalDate)}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>

        <Box padding="medium 0 0" direction="column" gap="small">
          <Typography tag="p" variant="p-t1">
            {TIER_PLANS_LABELS[tier]}
          </Typography>

          {!!billingSubscription?.billingInterval && (
            <BillingTierTilePricingBreakdown
              billingInterval={billingSubscription.billingInterval}
              addons={currentAddons}
            />
          )}

          {!billedExternally && tier === BillingTier.V4Free && (
            <Typography tag="p" variant="p-t4" color="secondary">
              $0 / month
            </Typography>
          )}

          {billedExternally && (
            <Typography tag="p" variant="p-body3">
              Billing for this account is managed externally. If you need support please contact
              your account manager for details.
            </Typography>
          )}
        </Box>
      </Box>
      {onTrialUntil && (
        <Box gap="medium" direction="column" className={styles.trialProgress}>
          <Box justify="between">
            <Typography tag="p" variant="p-body3">
              {isTrialFinished
                ? "Your trial has ended"
                : `Your trial ends on ${formatBillingDate(onTrialUntil)}`}
            </Typography>
            {!isTrialFinished && distanceInDays && (
              <Typography tag="p" variant="p-body3">
                {distanceInDays} left
              </Typography>
            )}
          </Box>
          <ProgressBar
            current={TRIAL_DAYS_COUNT - trialDaysLeft}
            max={TRIAL_DAYS_COUNT}
            ariaLabel="Free trial progress"
            color="var(--semantic-color-surface-data-non-semantic-7-primary)"
            fullWidth
            hideLabel
          />
        </Box>
      )}
    </TileWrapper>
  );
};

export default BillingTierTile;
