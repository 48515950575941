import { ReactNode } from "react";

import TextLink from "components/DocumentationSnippets/TextLink";
import { Lock } from "components/icons/generated";
import Banner from "ds/components/Banner";
import TileWrapper from "ds/components/Tile/Wrapper";

import DefaultTermsAndConditions from "./TermsAndConditions";

type PaymentsPartnerProps = {
  withTile?: boolean;
  termsAndConditions?: ReactNode;
};

const PaymentsPartner = ({ withTile, termsAndConditions }: PaymentsPartnerProps) => {
  const content = (
    <>
      <Banner
        variant="info"
        title={
          <>
            Payments are handled by{" "}
            <TextLink href="https://stripe.com/legal/privacy-center" inheritFont>
              Stripe Inc.
            </TextLink>
            , our secure partner
          </>
        }
        icon={Lock}
      />

      {termsAndConditions ?? <DefaultTermsAndConditions />}
    </>
  );

  return withTile ? (
    <TileWrapper direction="column" gap="large" grow="0">
      {content}
    </TileWrapper>
  ) : (
    content
  );
};

export default PaymentsPartner;
