import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { StringLike } from "@visx/scale";
import { format } from "date-fns";

import Box from "ds/components/Box";
import BarChart from "ds/components/Charts/BarChart";
import { AspectGranularity, AspectInput, AspectOutput, AspectType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import EmptyState from "ds/components/EmptyState";
import { Spinner } from "components/icons";
import { EmptystateChartColored } from "components/icons/generated";

import {
  adaptMetricToChart,
  adaptPercentilesToChart,
  adaptTimeFilterForApi,
  getChartItems,
} from "../utils";
import styles from "./styles.module.css";
import { GET_ASPECT } from "../gql";
import { getLegendTitleByGroup } from "./utils";
import { GroupByKey } from "./constants";
import { TimeFilter } from "../types";
import { ChartColor } from "./types";

type UsageChartProps = {
  aspectType: AspectType;
  timeFilter: TimeFilter;
  groupBy?: GroupByKey;
  tooltipHeader?: string;
  leftNumTicks?: number;
  showItemsInTooltip?: boolean;
  leftAxisLabel?: string;
  legendTitle?: string;
  showPercentiles?: boolean;
  colorMapper: (item: string) => ChartColor;
};

const UsageChart = ({
  aspectType,
  timeFilter,
  groupBy,
  tooltipHeader,
  leftNumTicks,
  colorMapper,
  leftAxisLabel,
  legendTitle,
  showItemsInTooltip = true,
  showPercentiles = true,
}: UsageChartProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{ usageAspect: AspectOutput }, { input: AspectInput }>(
    GET_ASPECT,
    {
      variables: {
        input: {
          type: aspectType,
          groupBy: groupBy || null,
          timeFilter: adaptTimeFilterForApi(timeFilter),
          granularity: AspectGranularity.Day,
        },
      },
      onError,
    }
  );

  const keys = useMemo(() => getChartItems(data?.usageAspect?.data || []), [data?.usageAspect]);

  const [colors, inactiveColors] = useMemo(
    () => [
      keys.map((item) => colorMapper(item).primary),
      keys.map((item) => colorMapper(item).inactive),
    ],
    [keys, colorMapper]
  );

  if (loading) {
    return (
      <Box grow="1" justify="center">
        <Spinner width="5rem" />
      </Box>
    );
  }

  if (!data?.usageAspect) return null; // TODO: handle error more gracefully

  if (!data?.usageAspect?.data?.length)
    return (
      <EmptyState
        title="There's no data to show in the usage chart yet"
        icon={EmptystateChartColored}
      />
    );

  const chartData = adaptMetricToChart(data.usageAspect, keys);

  return (
    <Box padding="large" className={styles.chartWrapper}>
      <BarChart
        data={chartData}
        items={keys}
        colors={colors}
        inactiveColors={inactiveColors}
        xKey="timestamp"
        formatXAxisLabel={(value: StringLike) => format((value as number) * 1000, "EEE d MMM")}
        tooltipHeader={tooltipHeader}
        legendTitle={legendTitle || getLegendTitleByGroup(groupBy)}
        leftNumTicks={leftNumTicks || 6}
        showItemsInTooltip={showItemsInTooltip && !!groupBy}
        percentiles={
          showPercentiles
            ? adaptPercentilesToChart(data?.usageAspect.summary?.percentiles)
            : undefined
        }
        leftAxisLabel={leftAxisLabel}
      />
    </Box>
  );
};

export default UsageChart;
