import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import ButtonIcon from "ds/components/ButtonIcon";
import { AzureIntegration, SpaceAccessLevel } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import Button from "ds/components/Button";
import { ArrowLeft } from "components/icons/generated";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import AzureIntegrationViewForm from "./Form";
import { showDeleteConfirmation } from "../DeleteConfirmation";

type AzureIntegrationViewProps = {
  integrations: AzureIntegration[];
};

type AzureIntegrationViewRouteParams = {
  integrationId: string;
};

const AzureIntegrationView = ({ integrations }: AzureIntegrationViewProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { integrationId } = useParams<AzureIntegrationViewRouteParams>();
  const location = useLocation();
  const integration = useMemo(
    () => integrations.find((i) => i.id === integrationId),
    [integrations, integrationId]
  );

  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const adminConsentFailedParam = searchParams.get("adminConsentFailed");
  const adminConsentFailed =
    !!adminConsentFailedParam && adminConsentFailedParam.toLowerCase() === "true";

  const handleBack = useCallback(() => {
    navigate("/cloud-integrations/azure");
  }, [navigate]);

  const provideAdminConsent = useCallback(() => {
    if (integration) {
      window.location.href = integration.adminConsentURL;
    }
  }, [integration]);

  const adminConsentButtonText = integration?.adminConsentProvided
    ? "Re-consent"
    : "Provide Consent";

  if (!integration) {
    return <NotFoundPage />;
  }

  const onDeleteClick = (clb: () => void) => {
    clb();
    showDeleteConfirmation({ integration }).then(handleBack);
  };

  const hasAttachedEntities = integration.attachedStacks.length > 0;

  const canManageItem =
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Admin ||
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Write;

  const hasManageAccess = viewer.admin || canManageItem;

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>
          <ButtonIcon
            icon={ArrowLeft}
            to="/cloud-integrations/azure"
            className={styles.backButton}
            variant="ghost"
          >
            Go back
          </ButtonIcon>
          Integration details
        </h3>
        {hasManageAccess && (
          <div className={styles.buttons}>
            <DropdownMenuEllipsis tooltip="Integration actions">
              <DropdownMenuItem href={`/cloud-integrations/azure/${integration.id}/edit`}>
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                isTooltipActive={hasAttachedEntities}
                tooltip="Cannot delete this integration because it is attached to a stack"
                onAction={() => onDeleteClick(close)}
                danger
                isDisabled={hasAttachedEntities}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuEllipsis>
            <Button
              onClick={() => provideAdminConsent()}
              variant="primary"
              size="small"
              className={styles.consentBtn}
            >
              {adminConsentButtonText}
            </Button>
          </div>
        )}
      </div>
      <AzureIntegrationViewForm integration={integration} adminConsentFailed={adminConsentFailed} />
    </>
  );
};

export default AzureIntegrationView;
