import { TIER_PLANS_LABELS } from "constants/tiers";
import BadgeNext from "ds/components/BadgeNext";
import { BadgeVariants } from "ds/components/BadgeNext/types";
import { BillingTier } from "types/generated";

type BillingTierBadgeProps = {
  tier: BillingTier;
};

const getTierBadgeVariant = (tier: BillingTier): BadgeVariants => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (tier) {
    case BillingTier.V4Starter:
      return "green";
    case BillingTier.V4StarterPlus:
      return "blue";
    case BillingTier.V4Business:
      return "yellow";
    case BillingTier.V4Enterprise:
      return "purple";
    default:
      return "gray";
  }
};

const BillingTierBadge = ({ tier }: BillingTierBadgeProps) => {
  return (
    <BadgeNext type="weak" variant={getTierBadgeVariant(tier)} text={TIER_PLANS_LABELS[tier]} />
  );
};

export default BillingTierBadge;
