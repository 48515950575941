import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import { BillingCycleInterval, BillingSubscription } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import { TIER_PLANS_LABELS } from "constants/tiers";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { AnalyticsPageBilling } from "hooks/useAnalytics/pages/billing";
import ButtonNew from "ds/components/Button/New";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import { BillingContext } from "../Context";
import BillingSubscriptionManagementLayout from "./Layout";
import { BillingSubscriptionManagementFields } from "./types";
import { formatBillingDate } from "../utils";
import { BILLING_CYCLE_SELECT_OPTIONS, SUMMARY_DRAWER_ID } from "./constants";
import styles from "./styles.module.css";
import BillingDiscountBanner from "../components/DiscountBanner";
import BillingSummaryItems from "../components/SummaryItems";
import BillingCancelSubscription from "../CancelSubscription";
import BillingSummaryItem from "../components/SummaryItem";
import { StripeSubscriptionStatus } from "../types";
import BillingAddonToggleFields from "../components/AddonToggleFields";
import useSummaryItems from "../hooks/useSummaryItems";
import useStarterPlanInfo from "../hooks/useStarterPlanInfo";
import { showBillingSubscriptionManagementSummaryDrawer } from "./SummaryDrawer";

type BillingSubscriptionManagementProps = {
  billingSubscription: BillingSubscription;
};

const BillingSubscriptionManagement = ({
  billingSubscription,
}: BillingSubscriptionManagementProps) => {
  const { tier, currentAddons } = useTypedContext(BillingContext);
  const [isCancelModalShown, setIsCancelModalShown] = useState(false);

  const currentBillingInterval =
    billingSubscription.billingInterval ?? BillingCycleInterval.Monthly;

  const form = useForm<BillingSubscriptionManagementFields>({
    defaultValues: {
      interval: currentBillingInterval,
      addons: currentAddons,
    },
    mode: "onChange",
  });

  const { formState, reset, watch, getValues } = form;

  const interval = watch("interval");

  const addons = watch("addons");

  useEffect(() => {
    if (currentBillingInterval) {
      const currentValues = getValues();

      // TODO: update when react-hook-form upgraded
      reset({
        ...currentValues,
        interval: currentBillingInterval,
        addons: currentAddons,
      });
    }
  }, [currentAddons, currentBillingInterval, getValues, reset]);

  const onCancelModalShow = () => {
    setIsCancelModalShown(true);
  };

  const summaryItems = useSummaryItems(interval, addons);

  const { total } = useStarterPlanInfo(interval, addons);

  const isSummaryDrawerVisible = useDrawerVisibilityForId(SUMMARY_DRAWER_ID);

  if (!billingSubscription) {
    return null;
  }

  const handleCancel = () => {
    reset();
  };

  const handleSummary = () => {
    showBillingSubscriptionManagementSummaryDrawer({
      id: SUMMARY_DRAWER_ID,
      tier,
      interval,
      addons,
      summaryItems,
      total,
    });
  };

  const handleCancelModalClose = () => {
    setIsCancelModalShown(false);
  };

  if (isCancelModalShown) {
    return <BillingCancelSubscription onClose={handleCancelModalClose} />;
  }

  return (
    <BillingSubscriptionManagementLayout
      subscriptionStatus={billingSubscription.status as StripeSubscriptionStatus}
      actions={
        <DropdownMenuEllipsis tooltip="Subscription actions">
          {billingSubscription.selfServePortalUrl && (
            <DropdownMenuItem
              href={billingSubscription.selfServePortalUrl}
              target="_blank"
              rel="noopener noreferrer"
              analyticsPage={AnalyticsPageBilling.BillingSubscription}
              analyticsTitle="Manage Payments Clicked"
            >
              Manage payments
            </DropdownMenuItem>
          )}

          <DropdownMenuItem
            onAction={onCancelModalShow}
            danger
            analyticsPage={AnalyticsPageBilling.BillingSubscription}
            analyticsTitle="Cancel Clicked"
          >
            Cancel
          </DropdownMenuItem>
        </DropdownMenuEllipsis>
      }
    >
      <FormProvider {...form}>
        <Box direction="column" className={styles.formWrapper}>
          <Box direction="column" className={styles.formSection}>
            <FormFieldViewText label="Plan type" value={TIER_PLANS_LABELS[tier]} />
            {billingSubscription.billingRenewalDate && (
              <FormFieldViewText
                label="Next renewal"
                value={formatBillingDate(billingSubscription.billingRenewalDate)}
              />
            )}

            <Controller
              name="interval"
              control={form.control}
              render={({ field, fieldState }) => (
                <FormField label="Billing cycle">
                  {({ ariaInputProps }) => (
                    <Select
                      value={field.value}
                      options={BILLING_CYCLE_SELECT_OPTIONS}
                      onChange={field.onChange}
                      error={!!fieldState.error?.message}
                      disabled={isSummaryDrawerVisible}
                      ariaInputProps={ariaInputProps}
                    />
                  )}
                </FormField>
              )}
            />

            {interval === BillingCycleInterval.Monthly && (
              <BillingDiscountBanner margin="medium 0 0 0" />
            )}
          </Box>

          <Box direction="column" className={styles.formSection}>
            <Controller
              name="addons"
              control={form.control}
              render={({ field }) => (
                <BillingAddonToggleFields
                  addons={field.value}
                  interval={interval}
                  onChange={field.onChange}
                  disabled={isSummaryDrawerVisible}
                />
              )}
            />
          </Box>

          <Box direction="column" gap="x-large">
            <Box direction="column" gap="medium">
              <Typography tag="p" variant="p-t5">
                Recurring charge
              </Typography>
            </Box>

            <BillingSummaryItems gap="small" labelVariant="p-body3" items={summaryItems}>
              <BillingSummaryItem
                paddingBottom="small"
                label={
                  <Typography tag="span" variant="p-t7">
                    Total
                  </Typography>
                }
                value={
                  <Typography tag="span" variant="p-t6">
                    ${total}
                  </Typography>
                }
              />
            </BillingSummaryItems>
          </Box>

          <Box justify="end">
            <Box gap="medium">
              {formState.isDirty && (
                <ButtonNew
                  variant="secondary"
                  onPress={handleCancel}
                  disabled={isSummaryDrawerVisible}
                >
                  Cancel
                </ButtonNew>
              )}

              <ButtonNew
                variant="primary"
                onPress={handleSummary}
                disabled={!formState.isValid || !formState.isDirty || isSummaryDrawerVisible}
              >
                Update subscription
              </ButtonNew>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </BillingSubscriptionManagementLayout>
  );
};

export default BillingSubscriptionManagement;
