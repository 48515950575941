import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardCvcElementOptions,
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";

import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import { useLoadStripe } from "hooks/useLoadStripe";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type Options = StripeCardNumberElementOptions &
  StripeCardExpiryElementOptions &
  StripeCardCvcElementOptions;

const getComputedOptions = (): Options => {
  const styles = getComputedStyle(document.body);

  return {
    style: {
      base: {
        color: styles.getPropertyValue("--color-default-primary"),
        fontSize: "13px",
        fontWeight: 400,
        "::placeholder": {
          color: `${styles.getPropertyValue("--input-text-placeholder-default-enabled")}`,
        },
      },
      invalid: {
        color: styles.getPropertyValue("--semantic-color-surface-status-danger-default"),
      },
    },
  };
};

type BillingCardFieldsProps = {
  onValidationUpdate: (isValid: boolean) => void;
};

const BillingCardFields = ({ onValidationUpdate }: BillingCardFieldsProps) => {
  const { currentTheme } = useTypedContext(ThemeContext);
  const [options, setOptions] = useState<Options>(getComputedOptions);
  const { error: stripeError } = useLoadStripe();
  const [cardErrorMessage, setCardErrorMessage] = useState<string>();
  const [expirationDateErrorMessage, setExpirationDateErrorMessage] = useState<string>();
  const [cvcErrorMessage, setCvcErrorMessage] = useState<string>();

  useEffect(() => {
    // Wait for the next tick to make sure the styles are applied
    queueMicrotask(() => {
      setOptions(getComputedOptions());
    });
  }, [currentTheme]);

  useEffect(() => {
    onValidationUpdate(
      cardErrorMessage === "" && expirationDateErrorMessage === "" && cvcErrorMessage === ""
    );
  }, [cardErrorMessage, expirationDateErrorMessage, cvcErrorMessage, onValidationUpdate]);

  const cardNumberOptions = useMemo(
    () => ({
      ...options,
      showIcon: true,
    }),
    [options]
  );

  if (stripeError) {
    return null;
  }

  const validateCardOnChange = (event: StripeCardNumberElementChangeEvent) => {
    if (event.empty) {
      setCardErrorMessage("Card number is required");
    } else {
      setCardErrorMessage(event.error?.message ?? "");
    }
  };

  const validateExpirationDateOnChange = (event: StripeCardExpiryElementChangeEvent) => {
    if (event.empty) {
      setExpirationDateErrorMessage("Expiration date is required");
    } else {
      setExpirationDateErrorMessage(event.error?.message ?? "");
    }
  };

  const validateCvcOnChange = (event: StripeCardCvcElementChangeEvent) => {
    if (event.empty) {
      setCvcErrorMessage("CVC is required");
    } else {
      setCvcErrorMessage(event.error?.message ?? "");
    }
  };

  return (
    <Box direction="column" gap="x-large" className={styles.cardFields}>
      <FormField
        label="Card number"
        noMargin
        error={cardErrorMessage}
        className={cx(!!cardErrorMessage && styles.invalid)}
      >
        <CardNumberElement
          id="cardNumber"
          options={cardNumberOptions}
          onChange={validateCardOnChange}
        />
      </FormField>
      <Box grid gridTemplate="1fr 1fr" gap="medium">
        <FormField
          label="Expiration date"
          noMargin
          error={expirationDateErrorMessage}
          className={cx(!!expirationDateErrorMessage && styles.invalid)}
        >
          <CardExpiryElement
            options={options}
            id="expirationDate"
            onChange={validateExpirationDateOnChange}
          />
        </FormField>
        <FormField
          label="CVV/CVC"
          noMargin
          error={cvcErrorMessage}
          className={cx(!!cvcErrorMessage && styles.invalid)}
        >
          <CardCvcElement options={options} id="cvc" onChange={validateCvcOnChange} />
        </FormField>
      </Box>

      <Typography variant="p-body4" color="secondary" tag="p">
        For simplified billing, payments are handled by Stripe, Inc. We do not store or have access
        to your credit card data.
      </Typography>
    </Box>
  );
};

export default BillingCardFields;
