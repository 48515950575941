import { Cross, Tick } from "components/icons/generated";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { IconComponent } from "types/Icon";
import TooltipInfo from "ds/components/TooltipInfo";

type BillingFeatureItemIconProps =
  | {
      negative?: boolean;
      iconComponent?: never;
    }
  | {
      negative?: never;
      iconComponent: IconComponent;
    };

type BillingFeatureItemProps = {
  text: string;
  infoTooltip?: string;
} & BillingFeatureItemIconProps;

const BillingFeatureItem = ({
  text,
  negative,
  iconComponent,
  infoTooltip,
}: BillingFeatureItemProps) => {
  return (
    <Box gap="medium">
      {iconComponent && <Icon src={iconComponent} />}
      {!iconComponent && (
        <>
          {negative ? (
            <Icon src={Cross} color="danger" noShrink />
          ) : (
            <Icon src={Tick} color="success" noShrink />
          )}
        </>
      )}

      <TextEllipsis tooltip={text}>
        {(props) => (
          <Typography {...props} tag="p" variant="p-body3">
            {text}
          </Typography>
        )}
      </TextEllipsis>
      {infoTooltip && <TooltipInfo widthMode="maxWidthSm">{infoTooltip}</TooltipInfo>}
    </Box>
  );
};

export default BillingFeatureItem;
