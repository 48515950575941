import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";

import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Textarea from "ds/components/Textarea";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { BillingTier } from "types/generated";

import BillingTwoColumnLayout from "../components/TwoColumnLayout";
import BillingTwoColumnLayoutLeft from "../components/TwoColumnLayout/Left";
import BillingTwoColumnLayoutRight from "../components/TwoColumnLayout/Right";
import { CANCEL_SUBSCRIPTION, CancelSubscriptionMutation } from "./gql";
import { CancellationFeedbackFormFields } from "./types";
import BillingCancelSubscriptionAnnouncement from "./Announcement";
import { CANCEL_SUBSCRIPTION_REASONS_SELECT_OPTIONS } from "./constants";

type BillingCancelSubscriptionConfirmationProps = {
  onClose: () => void;
};

const BillingCancelSubscriptionConfirmation = ({
  onClose,
}: BillingCancelSubscriptionConfirmationProps) => {
  const navigate = useNavigate();
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationBilling,
  });

  const [cancelSubscription, { loading }] = useMutation<CancelSubscriptionMutation>(
    CANCEL_SUBSCRIPTION,
    {
      refetchQueries: ["GetBilling", "GetTier", "GetAccountSettings"],
      awaitRefetchQueries: true,
    }
  );

  const feedbackForm = useForm<CancellationFeedbackFormFields>({
    defaultValues: {
      reason: "",
      feedback: "",
    },
    mode: "onChange",
  });

  const {
    register,
    control,
    formState: { isDirty, isValid },
    handleSubmit,
  } = feedbackForm;

  const handleCancelSubscription = (formValues: CancellationFeedbackFormFields) => {
    cancelSubscription()
      .then((result) => {
        if (!result?.data?.isActive) {
          reportSuccess({
            message: `Subscription was successfully cancelled`,
          });
          trackSegmentEvent("SUBSCRIPTION_CANCELLED", {
            plan: BillingTier.V4Starter,
            reason: formValues.reason,
            feedback: formValues.feedback,
          });
          navigate("/settings/billing");
        }
      })
      .catch(onError);
  };

  return (
    <>
      <BillingTwoColumnLayout>
        <BillingTwoColumnLayoutLeft>
          <Box direction="column" fullWidth>
            <Box direction="column" margin="0 0 large 0" fullWidth>
              <Typography tag="h1" variant="p-t4" align="center">
                Let us know why you want to cancel
              </Typography>
              <Typography
                tag="p"
                variant="p-body2"
                align="center"
                color="secondary"
                margin="small 0 0 0"
              >
                By cancelling you change your subscription plan to Free.
              </Typography>
            </Box>
            <Box direction="column">
              <FormProvider {...feedbackForm}>
                <Controller
                  name="reason"
                  control={control}
                  rules={{ required: "Please select a reason" }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label="What’s the main reason for cancelling?"
                      error={fieldState.error?.message}
                    >
                      {({ ariaInputProps }) => (
                        <Select
                          value={field.value}
                          options={CANCEL_SUBSCRIPTION_REASONS_SELECT_OPTIONS}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                          ariaInputProps={ariaInputProps}
                        />
                      )}
                    </FormField>
                  )}
                />
                <FormField label="Anything else you’d like to share" isOptional>
                  {({ ariaInputProps }) => (
                    <Textarea
                      placeholder="Enter the feedback here"
                      {...register("feedback")}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              </FormProvider>
            </Box>
          </Box>
        </BillingTwoColumnLayoutLeft>
        <BillingTwoColumnLayoutRight>
          <Box direction="column">
            <BillingCancelSubscriptionAnnouncement />
          </Box>
        </BillingTwoColumnLayoutRight>
      </BillingTwoColumnLayout>
      <FullScreenModalFooter gap="medium" justify="end">
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Keep plan
        </Button>
        <Button
          variant="dangerPrimary"
          onClick={handleSubmit(handleCancelSubscription)}
          loading={loading}
          disabled={loading || !isDirty || !isValid}
        >
          Cancel subscription
        </Button>
      </FullScreenModalFooter>
    </>
  );
};

export default BillingCancelSubscriptionConfirmation;
