import { ReactNode } from "react";
import cx from "classnames";

import TileWrapper from "ds/components/Tile/Wrapper";

import styles from "./styles.module.css";

export enum AnnouncementTileBackgroundType {
  Clouds = "clouds",
  Planets = "planets",
}

type AnnouncementTileProps = {
  children: ReactNode;
  className?: string;
  backgroundType: AnnouncementTileBackgroundType;
};

const AnnouncementTile = ({ children, backgroundType, className }: AnnouncementTileProps) => {
  return (
    <TileWrapper
      grow="0"
      noBorder
      className={cx(styles.announcement, styles[backgroundType], className)}
    >
      {children}
    </TileWrapper>
  );
};

export default AnnouncementTile;
